import { NgModule } from "@angular/core";
import { CommonModule } from "@angular/common";
import { RouterModule } from "@angular/router";
import { NgbModule } from "@ng-bootstrap/ng-bootstrap";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations"; // Only needed in root module
import { BrowserModule } from "@angular/platform-browser"; // Only needed in root module

// Components
import { LandingLayoutComponent } from "./components/layouts/landing-layout/landing-layout.component";
import { AuthLayoutComponent } from "./components/layouts/auth-layout/auth-layout.component";
import { HeaderComponent } from "@app/views/landing/components/header/header.component"; // Use @app alias if configured
import { SearchComponent } from "../views/landing/search/search.component"; // Consider using @app alias
import { FooterComponent } from "../views/landing/components/footer/footer.component"; // Consider using @app alias

// Directives
import { ScrollToDirective } from "./directives/scroll-to.directive";

const COMPONENTS = [
  LandingLayoutComponent,
  AuthLayoutComponent,
  HeaderComponent,
  SearchComponent,
  FooterComponent,
];

const DIRECTIVES = [ScrollToDirective];

@NgModule({
  imports: [CommonModule, NgbModule, RouterModule],
  declarations: [...COMPONENTS, ...DIRECTIVES],
  exports: [
    ...COMPONENTS,
    ...DIRECTIVES,
    CommonModule,
    NgbModule,
    RouterModule,
  ], // Export commonly used modules
})
export class SharedModule {}
