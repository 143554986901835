import { Component, OnInit, OnDestroy } from "@angular/core";
import { ActivatedRoute, Router, ParamMap } from "@angular/router";
import { Subscription } from "rxjs";

@Component({ standalone: false,
  selector: "app-dashboard-layout",
  templateUrl: "./dashboard-layout.component.html",
  styleUrls: ["./dashboard-layout.component.scss"],
})
export class DashboardLayoutComponent implements OnInit, OnDestroy {
  orgId: string | null = null;
  private paramSubscription: Subscription | undefined;
  isSidebarCollapsed = false;

  constructor(private route: ActivatedRoute, private router: Router) {}

  ngOnInit(): void {}

  ngOnDestroy(): void {}

  onToggleSidebar(isCollapsed: boolean): void {
    this.isSidebarCollapsed = isCollapsed;
  }
}
