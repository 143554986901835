import { Component, Input, OnInit, Renderer2 } from "@angular/core";
import algoliasearch from "algoliasearch/lite";
import instantsearch from "instantsearch.js";
import {
  searchBox,
  hits,
  pagination,
  stats,
  refinementList,
  clearRefinements,
  currentRefinements,
  configure,
} from "instantsearch.js/es/widgets";
import { environment } from "@env/environment";
import { ActivatedRoute, Router } from "@angular/router";

@Component({ standalone: false,
  selector: "app-search",
  templateUrl: "./search.component.html",
  styleUrls: ["./search.component.scss"],
})
export class SearchComponent implements OnInit {
  @Input() city: string = "";
  @Input() state: string = "";
  @Input() lat: number = 0;
  @Input() lng: number = 0;

  searchTerm: string | null = "";
  locationFilters: { city?: string; state?: string } = {};
  showFilters: boolean = false;
  searchInstance: any;
  nearbyCities: any[] = [];

  constructor(private renderer: Renderer2) {}

  ngOnInit(): void {
    const searchClient = algoliasearch(
      environment.algolia.appId,
      environment.algolia.apiKey
    );

    this.searchInstance = instantsearch({
      indexName: environment.algolia.indexName,
      searchClient,
    });

    // Use the city and state inputs
    this.locationFilters = {
      city: this.city,
      state: this.state,
    };
    console.log("Location filters from inputs:", this.locationFilters);

    // Configure the default filters
    this.searchInstance.addWidget(
      configure({
        filters: `location.state:"${this.state}" AND location.city:"${this.city}"`,
      })
    );

    // Search Box
    this.searchInstance.addWidget(
      searchBox({
        container: "#searchbox",
        placeholder: "Search for activities...",
        showReset: true,
      })
    );

    // Hits
    this.searchInstance.addWidget(
      hits({
        container: "#hits",
        transformItems: (items) =>
          items.map((item) => ({
            ...item,
            image_url: this.getSportImage(item.sport),
          })),
        templates: {
          empty: ({ query }, { html }) =>
            html`<div class="no-results-container">
              ${query
                ? html`<div class="no-results-message">
                    <p>
                      No results have been found for
                      <strong> ${query}</strong>.
                    </p>
                  </div>`
                : html`<div class="no-results-message">
                    <p>
                      We have not yet expanded to
                      <strong> ${this.city}, ${this.state}</strong>.
                    </p>
                  </div>`}
            </div>`,
          item: `
<div class="result-item card mb-3">
  <div class="row no-gutters">
    <div class="col-4 col-md-3 d-flex align-items-stretch">
      <img src="{{image_url}}" class="card-img img-fluid" alt="{{name}}" />
    </div>
    <div class="col-8 col-md-9 d-flex align-items-stretch">
      <div class="card-body d-flex flex-column justify-content-between">
        <h5 class="card-title">{{#helpers.highlight}}{ "attribute": "name" }{{/helpers.highlight}}</h5>
        <p class="card-text">{{#helpers.highlight}}{ "attribute": "description" }{{/helpers.highlight}}</p>
        <p class="card-text"><strong>Date:</strong> {{start_date}}</p>
        <p class="card-text"><strong>Time:</strong> {{time}}</p>
        <p class="card-text"><strong>Venue:</strong> {{venue}}</p>
        <p class="card-text"><strong>Price:</strong> {{price_per_person}}</p>
        <a href="/details/{{objectID}}" class="stretched-link"></a>
      </div>
    </div>
  </div>
</div>
          `,
        },
      })
    );

    // Pagination
    this.searchInstance.addWidget(
      pagination({
        container: "#pagination",
      })
    );

    // Stats
    this.searchInstance.addWidget(
      stats({
        container: "#stats",
        templates: {
          text(data, { html }) {
            let count = "";

            if (data.hasManyResults) {
              count += `${data.nbHits} Results`;
            } else if (data.hasOneResult) {
              count += `1 Result`;
            } else {
              count += `0 Results`;
            }

            return html`<span>${count}</span>`;
          },
        },
      })
    );

    // Refinement Lists
    this.addRefinementList("sport", "#sport-list");
    this.addRefinementList("day_of_week", "#day-of-week-list");
    this.addRefinementList("gender", "#gender-list");
    this.addRefinementList("neighborhood", "#neighborhood-list");
    this.addRefinementList("venue", "#venue-list");
    this.addRefinementList("skill_level", "#skill-level-list");
    this.addRefinementList("tags", "#tags-list");

    this.addRefinementList("sport", "#popup-sport-list");
    this.addRefinementList("day_of_week", "#popup-day-of-week-list");
    this.addRefinementList("gender", "#popup-gender-list");
    this.addRefinementList("neighborhood", "#popup-neighborhood-list");
    this.addRefinementList("venue", "#popup-venue-list");
    this.addRefinementList("skill_level", "#popup-skill-level-list");
    this.addRefinementList("tags", "#popup-tags-list");

    // Current Refinements
    this.searchInstance.addWidget(
      currentRefinements({
        container: "#current-refinements",
      })
    );

    // Clear Refinements
    this.searchInstance.addWidget(
      clearRefinements({
        container: "#clear-refinements",
      })
    );

    this.searchInstance.addWidget(
      clearRefinements({
        container: "#popup-clear-refinements",
      })
    );

    this.searchInstance.start();
    this.fetchNearbyCities();
  }

  addRefinementList(attribute: string, container: string) {
    this.searchInstance.addWidget(
      refinementList({
        container,
        attribute,
      })
    );
  }

  getSportImage(sport: string): string {
    const sportImages: { [key: string]: string } = {
      baseball: "assets/images/sports/baseball.jpg",
      basketball: "assets/images/sports/basketball.jpg",
      football: "assets/images/sports/football.jpg",
      soccer: "assets/images/sports/soccer.jpg",
      tennis: "assets/images/sports/tennis.jpg",
      golf: "assets/images/sports/golf.jpg",
      hockey: "assets/images/sports/hockey.jpg",
      cricket: "assets/images/sports/cricket.jpg",
      rugby: "assets/images/sports/rugby.jpg",
      swimming: "assets/images/sports/swimming.jpg",
      // Add other sports here
    };

    return sportImages[sport.toLowerCase()] || "assets/images/sports/golf.jpg";
  }

  openFilters() {
    this.showFilters = true;
    this.renderer.addClass(document.body, "no-scroll");
  }

  closeFilters() {
    this.showFilters = false;
    this.renderer.removeClass(document.body, "no-scroll");
  }

  viewCity(hit: CityHit) {
    // Implement the logic to view the selected city
    const path = `/locations/${hit.path}`;
    window.location.href = path;
    //TODO need to have the index store the city navigation url
  }

  fetchNearbyCities() {
    const searchClient = algoliasearch(
      environment.algolia.appId,
      environment.algolia.apiKey
    );

    const index = searchClient.initIndex("Startly_Location_Dev");

    const radiusMiles = 25;
    const radiusMeters = Math.round(radiusMiles * 1609.34); // Convert miles to meters and round to the nearest integer
    index
      .search<CityHit>("", {
        // Empty query to find all within radius
        aroundLatLng: `${this.lat}, ${this.lng}`,
        aroundRadius: radiusMeters,
      })
      .then(({ hits }) => {
        this.nearbyCities = hits.filter((hit) => hit.city !== this.city);
      })
      .catch((err) => {
        console.error(err);
      });
  }
}

interface CityHit {
  objectID: string;
  city: string;
  state: string;
  path: string;
}
