import { Injectable } from "@angular/core";
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
} from "@angular/common/http";
import { Observable, from } from "rxjs";
import { auth } from "@app/firebase-initialization";
import { switchMap } from "rxjs/operators";

@Injectable()
export class AuthInterceptor implements HttpInterceptor {
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    // Bypass auth for specific URL (e.g., public)
    if (request.url.includes("/public/")) {
      return next.handle(request); // Skip adding the Authorization header
    }

    const currentUser = auth.currentUser;
    if (currentUser) {
      return from(currentUser.getIdToken()).pipe(
        switchMap((token) => {
          const cloned = request.clone({
            setHeaders: {
              Authorization: `Bearer ${token}`,
            },
          });
          return next.handle(cloned);
        })
      );
    } else {
      // Handle the case when there is no authenticated user
      return next.handle(request);
    }
  }
}
